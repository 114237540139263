/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, url, type, articleDetails }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={
        type === "article"
          ? [
              {
                name: `description`,
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `article`,
              },
              {
                property: `og:url`,
                content: url,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:image`,
                content:
                  `https://media.graphcms.com/resize=w:1200,fit:crop/output=format:webp/compress/` +
                  articleDetails.image.handle,
              },

              {
                property: `og:locale`,
                content: `en_US`,
              },
              {
                property: `og:article:published_time`,
                content: articleDetails.publishedTime,
              },
              {
                property: `og:article:author`,
                content: articleDetails.authorFirstName,
              },
              {
                property: `og:article:author`,
                content: articleDetails.authorLastName,
              },
              {
                property: `og:article:section`,
                content: articleDetails.section,
              },

              {
                name: `twitter:card`,
                content: `summary_large_image`,
              },
              {
                name: `twitter:url`,
                content: url,
              },
              {
                name: `twitter:creator`,
                content: `@billsbysaas`,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `twitter:image`,
                content:
                  `https://media.graphcms.com/resize=w:1200,fit:crop/output=format:webp/compress/` +
                  articleDetails.image.handle,
              },
            ].concat(meta)
          : [
              {
                name: `description`,
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:url`,
                content: url,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:image`,
                content: require("../images/BillsbyOG.png"),
              },
              {
                property: `og:image`,
                content: require("../images/BillsbyOG.png"),
              },

              {
                name: `twitter:card`,
                content: `summary_large_image`,
              },
              {
                name: `twitter:url`,
                content: url,
              },
              {
                name: `twitter:creator`,
                content: `@billsbysaas`,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `twitter:image`,
                content: require("../images/BillsbyOG.png"),
              },
            ].concat(meta)
      }
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
